export const images = [
  {
    src: "/images/events/august/highlights/1.jpeg",
    original: "/images/events/august/highlights/1.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/2.jpeg",
    original: "/images/events/august/highlights/2.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/3.jpeg",
    original: "/images/events/august/highlights/3.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/4.jpeg",
    original: "/images/events/august/highlights/4.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/5.jpeg",
    original: "/images/events/august/highlights/5.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/6.jpeg",
    original: "/images/events/august/highlights/6.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/7.jpeg",
    original: "/images/events/august/highlights/7.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/8.jpeg",
    original: "/images/events/august/highlights/8.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/9.jpeg",
    original: "/images/events/august/highlights/9.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/10.jpeg",
    original: "/images/events/august/highlights/10.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/11.jpeg",
    original: "/images/events/august/highlights/11.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/12.jpeg",
    original: "/images/events/august/highlights/12.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/13.jpeg",
    original: "/images/events/august/highlights/13.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/14.jpeg",
    original: "/images/events/august/highlights/14.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/15.jpeg",
    original: "/images/events/august/highlights/15.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/16.jpeg",
    original: "/images/events/august/highlights/16.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/17.jpeg",
    original: "/images/events/august/highlights/17.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/18.jpeg",
    original: "/images/events/august/highlights/18.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/19.jpeg",
    original: "/images/events/august/highlights/19.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/20.jpeg",
    original: "/images/events/august/highlights/20.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/21.jpeg",
    original: "/images/events/august/highlights/21.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/22.jpeg",
    original: "/images/events/august/highlights/22.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/23.jpeg",
    original: "/images/events/august/highlights/23.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/24.jpeg",
    original: "/images/events/august/highlights/24.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/25.jpeg",
    original: "/images/events/august/highlights/25.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/26.jpeg",
    original: "/images/events/august/highlights/26.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/27.jpeg",
    original: "/images/events/august/highlights/27.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/28.jpeg",
    original: "/images/events/august/highlights/28.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/29.jpeg",
    original: "/images/events/august/highlights/29.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  },{
    src: "/images/events/august/highlights/30.jpeg",
    original: "/images/events/august/highlights/30.jpeg",
    width: 800,
    height: 500,
    caption: "Culture Beat Photo",
  }
];