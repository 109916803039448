import React from 'react';
import styled from 'styled-components';
import videoMp4 from '../media/videos/video_culture_beat_with_events.mp4';

const Player = styled.div`
    position: relative;
    background-size: cover;
    z-index: 0;
    top: 50px;
    width: 100%;
    text-align: center;
    overflow: hidden;
    margin-bottom: 100px;
    @media (max-width: 1300px) {
        margin-top: 50px;
        margin-bottom: 10px;
    }
`;

const StyledVideo = styled.video.attrs(props => ({
    preload: "preload",
    autoPlay: "autoPlay",
    loop: "loop",
    muted: "muted",
    playsInline: "playsInline"
}))`
    margin: 0 auto;
    width: 85%;
    z-index: -100;
`;


class HeaderVideo extends React.Component {
    render() {
        return (
            <Player>
                <StyledVideo>
                    <source src={videoMp4} type="video/mp4"/>
                    Your browser does not support HTML5 video.
                </StyledVideo>
            </Player>
        );
    }
}

export default HeaderVideo;