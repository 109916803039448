import {icon, toHtml} from "@fortawesome/fontawesome-svg-core";
import {faPlay, faStop} from '@fortawesome/free-solid-svg-icons'
import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import musicMix from './media/music/low_quality.m4a';
import videoGif from './media/videos/radio_background_circle.gif';
import spinner from './media/videos/spinner.gif';


const Wrapper = styled.div`
    display: block;
    position: fixed;
    top: 9px;
    z-index: 1000;
    right: 9%;
    @media (max-width: 800px) {
        right: 10%;
    }
`

const PlayerMask = styled.div`
    background-image: url(${props => props.isActive ? getSVGURI(faStop, 'white') : getSVGURI(faPlay, 'white')});
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    display: block;
    width: 50px;
    height: 50px;
    position: fixed;
    top: 9px;
    z-index: 9000;
    right: 9%;
    @media (max-width: 800px) {
        right: 12%;
    }
`

const PlayerBackground = styled.div`
    background-image: url(${props => props.isBuffering ? spinner : videoGif});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 50px;
    height: 50px;
    position: fixed;
    top: 9px;
    right: 9%;
    border-radius: 25px;
    @media (max-width: 800px) {
        right: 12%;
    }
`

const getSVGURI = (direction, color) => {
    const abstract = icon(direction).abstract[0];
    if (color) abstract.children[0].attributes.fill = color;
    return `data:image/svg+xml;base64,${btoa(toHtml(abstract))}`;
};

function RadioPlayer({togglePlay, isPlaying}) {

    const [isActive, setIsActive] = useState(isPlaying);
    const [isBuffering, setIsBuffering] = useState(false);
    const [volumeBar, setVolumeBar] = useState(0.5)

    useEffect(() => {
        setIsActive(isPlaying)
    }, [isPlaying])

    const handleClick = () => {
        setIsActive(current => !current);
    };

    const handleVolume = (e) => {
        setVolumeBar(e.target.value / 100)
    };

    const startBuffering = () => {
        setIsBuffering(true);
    };

    const stopBuffering = () => {
        setIsBuffering(false);
    };

    return (
        <Wrapper>
            <PlayerMask onClick={togglePlay} isActive={isActive}
                        style={{visibility: isBuffering ? 'hidden' : 'visible'}}>
                <ReactPlayer
                    url={musicMix}
                    visibility="hidden"
                    width="50px"
                    height="50px"
                    playing={isActive}
                    playsinline
                    fileConfig={{forceAudio: true}}
                    onBuffer={startBuffering}
                    onBufferEnd={stopBuffering}
                />
            </PlayerMask>
            <PlayerBackground isBuffering={isBuffering} style={{visibility: isActive ? 'visible' : 'hidden'}}/>
        </Wrapper>
    );

}

export default RadioPlayer;